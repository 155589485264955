import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';
import { useNavigate } from 'react-router-dom';
import watermarkImage from '../../assets/images/AI-Watermark.png';
import '../../App.css';

// Import Outfits
import blackSportsPanties from '../../assets/images/dolls/ImperialCommander/outfits/blackSportsPanties.png';
import blackSportsBra from '../../assets/images/dolls/ImperialCommander/outfits/blackSportsBra.png';
import whiteTankTop from '../../assets/images/dolls/ImperialCommander/outfits/whiteTank.png';
import imperialPants from '../../assets/images/dolls/ImperialCommander/outfits/imperialPants.png';
import blackBoots from '../../assets/images/dolls/ImperialCommander/outfits/blackBoots.png';
import imperialJacket from '../../assets/images/dolls/ImperialCommander/outfits/imperialJacket.png';
import imperialHat from '../../assets/images/dolls/ImperialCommander/outfits/imperialHat.png';
import sheerBlackTop from '../../assets/images/dolls/ImperialCommander/outfits/sheerBlackTop.png';
import redDress from '../../assets/images/dolls/ImperialCommander/outfits/redDress.png';
import goldHeels from '../../assets/images/dolls/ImperialCommander/outfits/goldHeels.png';
import bracelets from '../../assets/images/dolls/ImperialCommander/outfits/bracelets.png';

const DraggableImage = ({ id, src, x, y, naturalWidth, naturalHeight, scale, onDragEnd }) => {
    const [image] = useImage(src);
    const [pos, setPos] = useState({ x, y });

    useEffect(() => {
        setPos({ x, y });
    }, [x, y]);

    const handleDragMove = (e) => {
        let newX = e.target.x();
        let newY = e.target.y();
        setPos({ x: newX, y: newY });
    };

    const handleDragEndFunc = () => {
        if (onDragEnd) {
            onDragEnd(id, pos.x, pos.y);
        }
    };

    return (
        <KonvaImage
            image={image}
            x={pos.x}
            y={pos.y}
            width={naturalWidth * scale}
            height={naturalHeight * scale}
            draggable
            onDragMove={handleDragMove}
            onDragEnd={handleDragEndFunc}
        />
    );
};

const ImperialCommanderDoll = () => {
    const [ImperialCommanderMain] = useImage(require('../../assets/images/dolls/ImperialCommander/ImperialCommander-main.jpg'));
    const [hairOverlay] = useImage(require('../../assets/images/dolls/ImperialCommander/HairOverlay.png'));
    const [hairUp] = useImage(require('../../assets/images/dolls/ImperialCommander/HairUp.png'));

    const [outfits, setOutfits] = useState([]);
    const [initialOutfits, setInitialOutfits] = useState({});
    const [hairDo, setHairDo] = useState(false); // Hair Up is active on load
    const [drawerOpen, setDrawerOpen] = useState(false);
    const drawerRef = useRef(null);
    const [scale, setScale] = useState(1);
    const [containerSize, setContainerSize] = useState({ width: 1024, height: 1920 });
    const navigate = useNavigate();

    // Wrap fixedOutfitPositions in useMemo
    const fixedOutfitPositions = useMemo(() => ({
        'black-sports-panties': { x: 416, y: 735, src: blackSportsPanties, naturalWidth: 292, naturalHeight: 244 },
        'black-sports-bra': { x: 370, y: 365, src: blackSportsBra, naturalWidth: 298, naturalHeight: 325 },
        'white-tank-top': { x: 366, y: 354, src: whiteTankTop, naturalWidth: 320, naturalHeight: 478 },
        'imperial-pants': { x: 310, y: 720, src: imperialPants, naturalWidth: 459, naturalHeight: 982 },
        'black-boots': { x: 275, y: 1340, src: blackBoots, naturalWidth: 461, naturalHeight: 524 },
        'imperial-jacket': { x: 300, y: 325, src: imperialJacket, naturalWidth: 508, naturalHeight: 655 },
        'imperial-hat': { x: 420, y: 35, src: imperialHat, naturalWidth: 219, naturalHeight: 157 },
        'black-sheer-top': { x: 371, y: 360, src: sheerBlackTop, naturalWidth: 317, naturalHeight: 480 },
        'red-dress': { x: 370, y: 355, src: redDress, naturalWidth: 349, naturalHeight: 827 }, // Scaled to the dress dimensions
        'bracelets': { x: 305, y: 835, src: bracelets, naturalWidth: 503, naturalHeight: 116 },
        'gold-heels': { x: 275, y: 1650, src: goldHeels, naturalWidth: 438, naturalHeight: 214 },
    }), []);

    useEffect(() => {
        const updateScaleAndContainer = () => {
            const windowHeight = window.innerHeight;
            const originalHeight = 1920;

            const newScale = windowHeight / originalHeight;
            const containerWidth = 1024 * newScale;
            const containerHeight = originalHeight * newScale;

            setScale(newScale);
            setContainerSize({ width: containerWidth, height: containerHeight });
        };

        updateScaleAndContainer();
        window.addEventListener('resize', updateScaleAndContainer);

        return () => window.removeEventListener('resize', updateScaleAndContainer);
    }, []);

    const getCanvasCoordinates = useCallback((id) => {
        const fixedPosition = fixedOutfitPositions[id];
        if (!fixedPosition) return { x: 0, y: 0 };

        return {
            x: fixedPosition.x * scale,
            y: fixedPosition.y * scale,
        };
    }, [fixedOutfitPositions, scale]);

    useEffect(() => {
        const adjustOutfitPosition = () => {
            const initialOutfitsData = Object.keys(fixedOutfitPositions)
                .filter((id) => !['black-sheer-top', 'red-dress', 'gold-heels', 'bracelets'].includes(id))
                .map((id) => {
                    const { x, y } = getCanvasCoordinates(id);
                    const { src, naturalWidth, naturalHeight } = fixedOutfitPositions[id];
                    return { id, src, x, y, naturalWidth, naturalHeight };
                });

            console.log('Initial Outfits Data:', initialOutfitsData); // Debug log
            setOutfits(initialOutfitsData);

            const allOutfitsData = Object.keys(fixedOutfitPositions).map((id) => {
                const { x, y } = getCanvasCoordinates(id);
                const { src, naturalWidth, naturalHeight } = fixedOutfitPositions[id];
                return { id, src, x, y, naturalWidth, naturalHeight };
            });

            const initialPositions = {};
            allOutfitsData.forEach((outfit) => {
                initialPositions[outfit.id] = { x: outfit.x, y: outfit.y };
            });

            setInitialOutfits(initialPositions);
        };

        adjustOutfitPosition();
    }, [getCanvasCoordinates, fixedOutfitPositions]);

    const handleAddOutfit = (outfit) => {
        const { x, y } = getCanvasCoordinates(outfit.id);

        if (!outfits.find((o) => o.id === outfit.id)) {
            const { src, naturalWidth, naturalHeight } = fixedOutfitPositions[outfit.id];
            setOutfits([...outfits, { id: outfit.id, src, x, y, naturalWidth, naturalHeight }]);
        }
    };

    const handleRemoveOutfit = (id) => {
        setOutfits((currentOutfits) => currentOutfits.filter((outfit) => outfit.id !== id));
    };

    const handleBringToFront = (id) => {
        const outfit = outfits.find((outfit) => outfit.id === id);
        setOutfits(outfits.filter((outfit) => outfit.id !== id).concat(outfit));
    };

    const handleDragEnd = (id, x, y) => {
        const initialPosition = initialOutfits[id];
        const dragThreshold = 50;

        const hasMovedFar = Math.abs(x - initialPosition.x) > dragThreshold || Math.abs(y - initialPosition.y) > dragThreshold;

        if (hasMovedFar) {
            handleRemoveOutfit(id);
        } else {
            setOutfits(outfits.map((outfit) => (outfit.id === id ? { ...outfit, x, y } : outfit)));
        }
    };

    console.log('Outfits:', outfits); // Debug log

    return (
        <div className="doll-page" style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: containerSize.width, height: containerSize.height, position: 'relative' }}>
                <button className="drawer-toggle" onClick={() => setDrawerOpen(!drawerOpen)}>☰</button>
                <div ref={drawerRef} className={`drawer ${drawerOpen ? 'open' : ''}`}>
                    <div className="drawer-content">
                        <button className="back-button-free" onClick={() => navigate('/select-doll')}> {'<- Return to Doll Selection'}</button>
                        <button className="hair-button" onClick={() => setHairDo(!hairDo)}> {hairDo ? 'Hair Up' : 'Hair Down'}</button>
                        {Object.keys(fixedOutfitPositions).map((id) => {
                            const isAdded = outfits.find((o) => o.id === id);
                            const { src } = fixedOutfitPositions[id];
                            return (
                                <div key={id} className="menu-item">
                                    <span>{id.replace(/-/g, ' ')}</span>
                                    {!isAdded ? (
                                        <button onClick={() => handleAddOutfit({ id, src })}>Add</button>
                                    ) : (
                                        <>
                                            <button onClick={() => handleRemoveOutfit(id)}>Remove</button>
                                            <button onClick={() => handleBringToFront(id)}>Bring to Front</button>
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>

                <Stage width={containerSize.width} height={containerSize.height}>
                    {/* Background Layer */}
                    <Layer>
                        {ImperialCommanderMain && (
                            <KonvaImage
                                image={ImperialCommanderMain}
                                width={1024 * scale}
                                height={1920 * scale}
                                x={0}
                                y={0}
                            />
                        )}
                    </Layer>

                    {/* HairUp Layer (if hairDo is false) */}
                    <Layer>
                        {!hairDo && hairUp && (
                            <KonvaImage
                                image={hairUp}
                                width={1024 * scale}
                                height={1920 * scale}
                                x={0}
                                y={0}
                                listening={false}
                            />
                        )}
                    </Layer>

                    {/* Outfits Layer */}
                    <Layer>
                        {outfits.map((outfit) => (
                            outfit.src && (
                                <DraggableImage
                                    key={outfit.id}
                                    id={outfit.id}
                                    src={outfit.src}
                                    x={outfit.x}
                                    y={outfit.y}
                                    naturalWidth={outfit.naturalWidth}
                                    naturalHeight={outfit.naturalHeight}
                                    scale={scale}
                                    onDragEnd={handleDragEnd}
                                />
                            )
                        ))}
                    </Layer>

                    {/* HairOverlay Layer (if hairDo is true) */}
                    <Layer>
                        {hairDo && hairOverlay && (
                            <KonvaImage
                                image={hairOverlay}
                                width={1024 * scale}
                                height={1920 * scale}
                                x={0}
                                y={0}
                                listening={false}
                            />
                        )}
                    </Layer>
                </Stage>
            </div>
            <div className="doll-page-watermark-container" style={{ position: 'absolute', left: '50%', bottom: '100px', zIndex: 2 }}>
                <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
                    <img src={watermarkImage} alt="Watermark" className="doll-page-watermark" />
                </a>
            </div>
        </div>
    );
};

export default ImperialCommanderDoll;
