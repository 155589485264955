import React from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';  // Use HTML5 backend
import Analytics from './Analytics';


import HomePage from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import ModeSelectionPage from './pages/ModeSelectionPage';


import DeathScene from "./components/deathScene";
import DollSelectionPage from './pages/DollSelectionPage';
// Dolls
import ElvenHunterDoll from './pages/dolls/ElvenHunterDoll';
import CyberHackerDoll from './pages/dolls/CyberHackerDoll';
import DarkMageDoll from './pages/dolls/DarkMageDoll';
import PhoenixFighterDoll from './pages/dolls/PhoenixFighterDoll';
import TwilekScoundrelDoll from './pages/dolls/TwilekScoundrelDoll';
import DemonSlayerRedDoll from './pages/dolls/DemonSlayerRedDoll';
import TwoBDoll from './pages/dolls/2BDoll';
import SallyDoll from './pages/dolls/SallyDoll';
import ImperialCommanderDoll from './pages/dolls/ImperialCommander';
import AsajjDoll from './pages/dolls/Asajj';


//Story
import ElvenHunterIntro from './pages/storymode/ElvenHunterIntro'
import StorySelectionPage from './pages/StorySelectionPage';
import BattleTemplate from './pages/BattleTemplate';
import ElvenHunterStoryScene01 from './pages/ElvenHunter/ElvenHunterStoryScene01';
import ElvenHunterBattle01 from './pages/ElvenHunter/ElvenHunterBattle01';
import ElvenHunterPass01 from './pages/ElvenHunter/ElvenHunterPass01';
import ElvenHunterChase01 from './pages/ElvenHunter/ElvenHunterChase01';
import './App.css';



const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>  {/* Use the HTML5 backend for drag-and-drop */}
      <Router>
        <Analytics /> {/* Add Google Analytics */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/mode-selection" element={<ModeSelectionPage />} />
          <Route path="/dashboard" element={<Dashboard />} />


          {/* Minimum Pledge $1 - Use component instead of JSX */}
          <Route path="/select-doll" element={<DollSelectionPage />} />
          <Route path="/doll/elvenhunter" element={<ElvenHunterDoll />} />
          <Route path="/doll/cyberhacker" element={<CyberHackerDoll />} />
          <Route path="/doll/darkmage" element={<DarkMageDoll />}/>
          <Route path="/doll/phoenixfighter" element={<PhoenixFighterDoll />} />
          <Route path="/doll/twilekscoundrel" element={<TwilekScoundrelDoll />} />
          <Route path="/doll/demonslayerred" element={<DemonSlayerRedDoll />} />
          <Route path="/doll/2B" element={<TwoBDoll />} />
          <Route path="/doll/Sally" element={<SallyDoll />} />
          <Route path="/doll/imperialcommander" element={<ImperialCommanderDoll />} />
          <Route path="/doll/asajj" element={<AsajjDoll />} />

          {/* Story Mode Test - Elven Hunter */}
          <Route path="/story/elvenhunter/intro" element={<ElvenHunterIntro />} />

          {/* Story Mode - Elven Hunter */}
          <Route path="/story-selection" element={<StorySelectionPage />} />
          <Route path="/story/elvenhunter/scene01" element={<ElvenHunterStoryScene01 />} />
          <Route path="/story/elvenhunter/battle01" element={<ElvenHunterBattle01 />} />
          <Route path="/story/elvenhunter/pass01" element={<ElvenHunterPass01 />} />
          <Route path="/story/elvenhunter/chase01" element={<ElvenHunterChase01 />} />
        </Routes>
      </Router>
    </DndProvider>
  );
};

export default App;
