import React from 'react';
import { useNavigate } from 'react-router-dom';
import './homepage.css'; // Import the new CSS file
import watermark from '../assets/images/AI-Watermark.png'; // Import AI watermark

const HomePage = () => {
  const navigate = useNavigate();

  // Handle agree (navigate to mode-selection)
  const handleAgree = () => {
    navigate('/mode-selection');  // Navigate to the main content
  };

  // Handle disagree (redirect to Google)
  const handleDisagree = () => {
    window.location.href = 'https://www.google.com';
  };

  return (
    <div className="home-page">
      {/* Display AI watermark logo */}
      <img
        src={watermark}
        alt="AI Pinups Watermark"
        className="watermark-image"
      />

      {/* Welcome message */}
      <h1>Welcome to the AI Pinups Game!</h1>
      <p>You must be 18 to enter this site.</p>

      {/* Agree and Disagree buttons */}
      <div className="button-group">
        <button className="primary-button" onClick={handleAgree}>
          I Agree
        </button>
        <button className="secondary-button" onClick={handleDisagree}>
          Disagree
        </button>
      </div>

      {/* Footer Links */}
      <div className="footer-links">
        <a href="/privacy.html" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        {' | '}
        <a href="/ToS.html" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
        {' | '}
        <a href="mailto:support@ai-pinups.io" target="_blank" rel="noopener noreferrer">
          Contact Support
        </a>
        {' | '}
        <a
          href="https://www.patreon.com/c/AI_Pinups"
          target="_blank"
          rel="noopener noreferrer"
          className="patreon-link"
        >
          Support on Patreon
        </a>
      </div>
    </div>
  );
};

export default HomePage;
