import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';
import { useNavigate } from 'react-router-dom';
import watermarkImage from '../../assets/images/AI-Watermark.png';
import '../../App.css';

// Draggable Image Component
const DraggableImage = ({ id, src, x, y, scale, onDragEnd }) => {
  const [image] = useImage(src);
  const [pos, setPos] = useState({ x, y });

  const handleDragMove = (e) => {
    let newX = e.target.x();
    let newY = e.target.y();
    setPos({ x: newX, y: newY });
  };

  const handleDragEndFunc = () => {
    if (onDragEnd) {
      onDragEnd(id, pos.x, pos.y);
    }
  };

  return (
    <KonvaImage
      image={image}
      x={pos.x}
      y={pos.y}
      width={1024 * scale} // Uniform scaling applied to the width
      height={1920 * scale} // Uniform scaling applied to the height
      draggable
      onDragMove={handleDragMove}
      onDragEnd={handleDragEndFunc}
    />
  );
};

// Main Doll Component
const TwoBDoll = () => {
  const [outfits, setOutfits] = useState([]);
  const [initialOutfits, setInitialOutfits] = useState({});
  const [TwoBDollMain] = useImage(require('../../assets/images/dolls/2B/2B-Main.jpg'));
  const [headbandVisible, setHeadbandVisible] = useState(true);
  const [headbandImage] = useImage(require('../../assets/images/dolls/2B/headband-hair.png'));
  const [reflectionVisible, setReflectionVisible] = useState(false);
  const [reflectionImage] = useImage(require('../../assets/images/dolls/2B/boot-reflection.png'));
  const [topHair] = useImage(require('../../assets/images/dolls/2B/top-hair.png'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const drawerRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [imageTopOffset, setImageTopOffset] = useState(0);
  const [containerSize, setContainerSize] = useState({ width: 1024, height: 1920 });
  const navigate = useNavigate();

  // Function to update scale and container dimensions
  useEffect(() => {
    const updateScaleAndContainer = () => {
      const windowHeight = window.innerHeight;
      const originalHeight = 1920; // Height of the doll image

      // Use the height of the window to set the scale for the entire canvas
      const newScale = windowHeight / originalHeight;

      const containerWidth = 1024 * newScale;
      const containerHeight = originalHeight * newScale;

      setScale(newScale);
      setImageTopOffset(20 * newScale);
      setContainerSize({ width: containerWidth, height: containerHeight });
    };

    updateScaleAndContainer();
    window.addEventListener('resize', updateScaleAndContainer);

    return () => window.removeEventListener('resize', updateScaleAndContainer);
  }, []);

  // Handle Headband toggle
  const handleToggleHeadband = () => {
    setHeadbandVisible(!headbandVisible);
  };

  // Adjust outfit positions based on container size and scale
  const adjustOutfitPosition = () => {
    const outfitCenterX = (containerSize.width - 1024 * scale) / 2;
    const outfitCenterY = imageTopOffset;

    const initialOutfitsData = [
      { id: 'black-bikini-bottom', src: require('../../assets/images/dolls/2B/outfits/black-bikini-bottom.png'), x: outfitCenterX, y: outfitCenterY },
      { id: 'nipple-Xs', src: require('../../assets/images/dolls/2B/outfits/nipple-Xs.png'), x: outfitCenterX, y: outfitCenterY },
      { id: 'blindfold', src: require('../../assets/images/dolls/2B/outfits/blindfold.png'), x: outfitCenterX, y: outfitCenterY },
      { id: 'boots', src: require('../../assets/images/dolls/2B/outfits/boots.png'), x: outfitCenterX, y: outfitCenterY },
      { id: 'white-gloves', src: require('../../assets/images/dolls/2B/outfits/white-gloves.png'), x: outfitCenterX, y: outfitCenterY },
      { id: 'white-leotard', src: require('../../assets/images/dolls/2B/outfits/white-leotard.png'), x: outfitCenterX, y: outfitCenterY },
      { id: 'black-main-dress', src: require('../../assets/images/dolls/2B/outfits/black-main-dress.png'), x: outfitCenterX, y: outfitCenterY },
    ];

    setOutfits(initialOutfitsData);

    const initialPositions = {};
    initialOutfitsData.forEach(outfit => {
      initialPositions[outfit.id] = { x: outfitCenterX, y: outfitCenterY };
    });
    setInitialOutfits(initialPositions);
  };

  // Adjust outfit position on load and container size change
  useEffect(() => {
    adjustOutfitPosition();
  }, [scale, imageTopOffset, containerSize]);

  const handleAddOutfit = (outfit) => {
    const outfitCenterX = (containerSize.width - 1024 * scale) / 2;
    const outfitCenterY = imageTopOffset;

    if (!outfits.find(o => o.id === outfit.id)) {
      setOutfits([...outfits, { ...outfit, x: outfitCenterX, y: outfitCenterY }]);
      setInitialOutfits(prev => ({
        ...prev,
        [outfit.id]: { x: outfitCenterX, y: outfitCenterY }
      }));
    }
  };

  const handleRemoveOutfit = (id) => {
    setOutfits(outfits.filter(outfit => outfit.id !== id));
  };

  const handleBringToFront = (id) => {
    const outfit = outfits.find(outfit => outfit.id === id);
    setOutfits(outfits.filter(outfit => outfit.id !== id).concat(outfit));
  };

  const handleDragEnd = (id, x, y) => {
    const initialPosition = initialOutfits[id];
    const dragThreshold = 50;

    const hasMovedFar = Math.abs(x - initialPosition.x) > dragThreshold || Math.abs(y - initialPosition.y) > dragThreshold;

    if (hasMovedFar) {
      handleRemoveOutfit(id);
    } else {
      setOutfits(outfits.map(outfit => (outfit.id === id ? { ...outfit, x, y } : outfit)));
    }
  };

  useEffect(() => {
    if (outfits.find(o => o.id === 'boots')) {
      setReflectionVisible(true);
    } else {
      setReflectionVisible(false);
    }
  }, [outfits]);

  return (
    <div className="doll-page" style={{ display: 'flex', justifyContent: 'center' }}>
      {/* Game container that holds the doll and outfits */}
      <div style={{ width: containerSize.width, height: containerSize.height, position: 'relative' }}>
        <button className="drawer-toggle" onClick={() => setDrawerOpen(!drawerOpen)}>☰</button>
        <div ref={drawerRef} className={`drawer ${drawerOpen ? 'open' : ''}`}>
          <div className="drawer-content">
          <button className="back-button-free" onClick={() => navigate('/select-doll')}> {'<- Return to Doll Selection'}</button>
            <button className="headband-button" onClick={handleToggleHeadband}> {headbandVisible ? 'Remove Headband' : 'Add Headband'}</button>
            {/* Drawer outfit options */}
            {[
              { id: 'black-bikini-bottom', name: 'Black Bikini Bottom', src: require('../../assets/images/dolls/2B/outfits/black-bikini-bottom.png') },
              { id: 'nipple-Xs', name: 'Nipple Xs', src: require('../../assets/images/dolls/2B/outfits/nipple-Xs.png') },
              { id: 'blindfold', name: 'Blindfold', src: require('../../assets/images/dolls/2B/outfits/blindfold.png') },
              { id: 'boots', name: 'Boots', src: require('../../assets/images/dolls/2B/outfits/boots.png') },
              { id: 'white-gloves', name: 'White Gloves', src: require('../../assets/images/dolls/2B/outfits/white-gloves.png') },
              { id: 'white-leotard', name: 'White Leotard', src: require('../../assets/images/dolls/2B/outfits/white-leotard.png') },
              { id: 'black-main-dress', name: 'Black Dress', src: require('../../assets/images/dolls/2B/outfits/black-main-dress.png') },
            ].map(outfit => (
              <div key={outfit.id} className="menu-item">
                <span>{outfit.name}</span>
                {!outfits.find(o => o.id === outfit.id) && (
                  <button onClick={() => handleAddOutfit(outfit)}>Add</button>
                )}
                {outfits.find(o => o.id === outfit.id) && (
                  <>
                    <button onClick={() => handleRemoveOutfit(outfit.id)}>Remove</button>
                    <button onClick={() => handleBringToFront(outfit.id)}>Bring to Front</button>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>

        <Stage width={containerSize.width} height={containerSize.height}>
          <Layer>
            <KonvaImage
              image={TwoBDollMain}
              width={1024 * scale}
              height={1920 * scale}
              x={0}
              y={0}
            />
            {reflectionVisible && (
              <KonvaImage
                image={reflectionImage}
                width={1024 * scale}
                height={1920 * scale}
                x={0}
                y={0}
                listening={false}
              />
            )}
            {outfits.map(outfit => (
              <DraggableImage
                key={outfit.id}
                id={outfit.id}
                src={outfit.src}
                x={outfit.x}
                y={outfit.y}
                scale={scale} // Pass the uniform scale to the outfits
                onDragEnd={handleDragEnd}
              />
            ))}
          </Layer>

          <Layer>
            <KonvaImage
              image={topHair}
              width={1024 * scale}
              height={1920 * scale}
              x={0}
              y={0}
              listening={false}
            />
            {headbandVisible && (
              <KonvaImage
                image={headbandImage}
                width={1024 * scale}
                height={1920 * scale}
                x={0}
                y={0}
                listening={false}
              />
            )}
          </Layer>
        </Stage>
      </div>

      <div
        className="doll-page-watermark-container"
        style={{ position: 'absolute', left: '50%', bottom: '100px', zIndex: 2 }}
      >
        <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
          <img src={watermarkImage} alt="Watermark" className="doll-page-watermark" />
        </a>
      </div>
    </div>
  );
};

export default TwoBDoll;
