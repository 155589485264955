import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';
import { useNavigate } from 'react-router-dom';
import watermarkImage from '../../assets/images/AI-Watermark.png';
import '../../doll.css';

// Import Outfits
import purpleBikiniBottom from '../../assets/images/dolls/Asajj/outfits/purpleBikiniBottom.png';
import purpleBikiniTop from '../../assets/images/dolls/Asajj/outfits/purpleBikiniTop.png';
import legWraps from '../../assets/images/dolls/Asajj/outfits/legWraps.png';
import armWraps from '../../assets/images/dolls/Asajj/outfits/armWraps.png';
import midSectionWraps from '../../assets/images/dolls/Asajj/outfits/midSectionWraps.png';
import classicSkirt from '../../assets/images/dolls/Asajj/outfits/classicSkirt.png';
import classicTop from '../../assets/images/dolls/Asajj/outfits/classicTop.png';
import blackLeggings from '../../assets/images/dolls/Asajj/outfits/blackLeggings.png';
import bbBlueTop from '../../assets/images/dolls/Asajj/outfits/bbBlueTop.png';
import brownLeatherBelt from '../../assets/images/dolls/Asajj/outfits/brownLeatherBelt.png';
import brownLeatherGauntlets from '../../assets/images/dolls/Asajj/outfits/brownLeatherGuantlets.png';
import greyBoots from '../../assets/images/dolls/Asajj/outfits/greyBoots.png';



const DraggableImage = ({ id, src, x, y, naturalWidth, naturalHeight, scale, onDragEnd }) => {
    const [image] = useImage(src);
    const [pos, setPos] = useState({ x, y });

    useEffect(() => {
        setPos({ x, y });
    }, [x, y]);

    const handleDragMove = (e) => {
        let newX = e.target.x();
        let newY = e.target.y();
        setPos({ x: newX, y: newY });
    };

    const handleDragEndFunc = () => {
        if (onDragEnd) {
            onDragEnd(id, pos.x, pos.y);
        }
    };

    return (
        <KonvaImage
            image={image}
            x={pos.x}
            y={pos.y}
            width={naturalWidth * scale}
            height={naturalHeight * scale}
            draggable
            onDragMove={handleDragMove}
            onDragEnd={handleDragEndFunc}
        />
    );
};

const AsajjDoll = () => {
    const [AsajjMain] = useImage(require('../../assets/images/dolls/Asajj/Asajj-main.jpg'));
    const [hair] = useImage(require('../../assets/images/dolls/Asajj/Hair.png'));
    const [asajjBody] = useImage(require('../../assets/images/dolls/Asajj/Asajj-body.png'));
    const [frontSaber] = useImage(require('../../assets/images/dolls/Asajj/FrontSaber.png'));
    const [frontSaberYellow] = useImage(require('../../assets/images/dolls/Asajj/FrontSaberYellow.png'));
    const [backSaberBladeAndHilt] = useImage(require('../../assets/images/dolls/Asajj/BackSaberBladeAndHilt.png'));
    const [backSaberHilt] = useImage(require('../../assets/images/dolls/Asajj/BackSaberHilt.png'));
    const [outfits, setOutfits] = useState([]);
    const [currentOutfitSet, setCurrentOutfitSet] = useState('classic'); // Start with "classic"

    const [initialOutfits, setInitialOutfits] = useState({});
    const [hairDo, setHairDo] = useState(true); // Hair is inactive on load
    const [drawerOpen, setDrawerOpen] = useState(false);
    const drawerRef = useRef(null);
    const [scale, setScale] = useState(1);
    const [containerSize, setContainerSize] = useState({ width: 1024, height: 1920 });
    const navigate = useNavigate();

    // Wrap fixedOutfitPositions in useMemo
    const fixedOutfitPositions = useMemo(() => ({
        'all-purple-bikni-bottom': { x: 413, y: 678, src: purpleBikiniBottom, naturalWidth: 151, naturalHeight: 196 },
        'all-purple-bikini-top': { x: 405, y: 340, src: purpleBikiniTop, naturalWidth: 244, naturalHeight: 223 },
        'classic-leg-wraps-acc': { x: 300, y: 885, src: legWraps, naturalWidth: 454, naturalHeight: 987 },
        'classic-arm-wraps-acc': { x: 216, y: 525, src: armWraps, naturalWidth: 454, naturalHeight: 422 },
        'classic-mid-section-wrap-acc': { x: 410, y: 540, src: midSectionWraps, naturalWidth: 191, naturalHeight: 219 },
        'classic-skirt-bottom': { x: 270, y: 660, src: classicSkirt, naturalWidth: 491, naturalHeight: 1039 },
        'classic-top': { x: 305, y: 270, src: classicTop, naturalWidth: 344, naturalHeight: 315 },
        'bb-blackLeggings-bottom': { x: 285, y: 660, src: blackLeggings, naturalWidth: 446, naturalHeight: 1073 },
        'bb-Blue-top': { x: 204, y: 290, src: bbBlueTop, naturalWidth: 483, naturalHeight: 745 },
        'bb-greyBoots-acc': { x: 335, y: 1270, src: greyBoots, naturalWidth: 469, naturalHeight: 625 },
        'bb-brownLeatherGuantlets-acc': { x: 230, y: 645, src: brownLeatherGauntlets, naturalWidth: 439, naturalHeight: 275 },
        'bb-brownLeatherBelt-acc': { x: 345, y: 645, src: brownLeatherBelt, naturalWidth: 263, naturalHeight: 321 },




    }), []);



    useEffect(() => {
        const updateScaleAndContainer = () => {
            const windowHeight = window.innerHeight;
            const originalHeight = 1920;

            const newScale = windowHeight / originalHeight;
            const containerWidth = 1024 * newScale;
            const containerHeight = originalHeight * newScale;

            setScale(newScale);
            setContainerSize({ width: containerWidth, height: containerHeight });
        };

        updateScaleAndContainer();
        window.addEventListener('resize', updateScaleAndContainer);

        return () => window.removeEventListener('resize', updateScaleAndContainer);
    }, []);

    const [saberColor, setSaberColor] = useState('red'); // Default to red or any other color


    const [saberState, setSaberState] = useState({
        frontRed: true,
        backRed: true,
        frontYellow: false,
    });

    // Toggle visibility of individual sabers
    const toggleSaberVisibility = (saber) => {
        setSaberState((prevState) => ({
            ...prevState,
            [saber]: !prevState[saber],
        }));
    };

    // Toggle all sabers on/off or switch colors
    const toggleAllSabers = (color) => {
        if (color === 'off') {
            setSaberState({ frontRed: false, backRed: false, frontYellow: false });
        } else if (color === 'red') {
            setSaberState({ frontRed: true, backRed: true, frontYellow: false });
            setSaberColor('red');
        } else if (color === 'yellow') {
            setSaberState({ frontRed: false, backRed: false, frontYellow: true });
            setSaberColor('yellow');
        }
    };


    const [lightsaberControlsOpen, setLightsaberControlsOpen] = useState(false);


    const getCanvasCoordinates = useCallback((id) => {
        const fixedPosition = fixedOutfitPositions[id];
        if (!fixedPosition) return { x: 0, y: 0 };

        return {
            x: fixedPosition.x * scale,
            y: fixedPosition.y * scale,
        };
    }, [fixedOutfitPositions, scale]);


    useEffect(() => {
        const adjustOutfitPosition = () => {
            const initialOutfitsData = Object.keys(fixedOutfitPositions)
                .map((id) => {
                    const { x, y } = getCanvasCoordinates(id);
                    const { src, naturalWidth, naturalHeight } = fixedOutfitPositions[id];
                    return { id, src, x, y, naturalWidth, naturalHeight };
                });

            setOutfits(initialOutfitsData);

            const allOutfitsData = Object.keys(fixedOutfitPositions).map((id) => {
                const { x, y } = getCanvasCoordinates(id);
                const { src, naturalWidth, naturalHeight } = fixedOutfitPositions[id];
                return { id, src, x, y, naturalWidth, naturalHeight };
            });

            const initialPositions = {};
            allOutfitsData.forEach((outfit) => {
                initialPositions[outfit.id] = { x: outfit.x, y: outfit.y };
            });

            setInitialOutfits(initialPositions);
        };

        adjustOutfitPosition();
    }, [getCanvasCoordinates, fixedOutfitPositions]);

    useEffect(() => {
        const filteredOutfits = Object.entries(fixedOutfitPositions)
            .filter(([id]) => id.startsWith(currentOutfitSet) || id.startsWith('all')) // Include current set and shared outfits
            .map(([id, { x, y, src, naturalWidth, naturalHeight }]) => ({
                id,
                x: x * scale,
                y: y * scale,
                src,
                naturalWidth,
                naturalHeight,
            }));

        setOutfits(filteredOutfits);
    }, [fixedOutfitPositions, currentOutfitSet, scale]);





    const handleAddOutfit = (outfit) => {
        const { x, y } = getCanvasCoordinates(outfit.id);

        if (!outfits.find((o) => o.id === outfit.id)) {
            const { src, naturalWidth, naturalHeight } = fixedOutfitPositions[outfit.id];
            setOutfits([...outfits, { id: outfit.id, src, x, y, naturalWidth, naturalHeight }]);
        }
    };

    const handleRemoveOutfit = (id) => {
        setOutfits((currentOutfits) => currentOutfits.filter((outfit) => outfit.id !== id));
    };

    const handleBringToFront = (id) => {
        const outfit = outfits.find((outfit) => outfit.id === id);
        setOutfits(outfits.filter((outfit) => outfit.id !== id).concat(outfit));
    };

    const toggleOutfitSet = () => {
        setCurrentOutfitSet((prevSet) => {
            const newSet = prevSet === 'classic' ? 'bb' : 'classic';

            // Toggle hair and saber color based on the outfit set
            if (newSet === 'bb') {
                setHairDo(false); // Hair on for BB
                setSaberColor('yellow'); // Yellow saber for BB
            } else {
                setHairDo(true); // Hair off for Classic
                setSaberColor('red'); // Red saber for Classic
            }

            return newSet;
        });
    };



    const handleDragEnd = (id, x, y) => {
        const initialPosition = initialOutfits[id];
        const dragThreshold = 50;

        const hasMovedFar = Math.abs(x - initialPosition.x) > dragThreshold || Math.abs(y - initialPosition.y) > dragThreshold;

        if (hasMovedFar) {
            handleRemoveOutfit(id);
        } else {
            setOutfits(outfits.map((outfit) => (outfit.id === id ? { ...outfit, x, y } : outfit)));
        }
    };

    const [categoriesOpen, setCategoriesOpen] = useState({});

    return (
        <div className="doll-page" style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: containerSize.width, height: containerSize.height, position: 'relative' }}>
                <button
                    className="drawer-toggle"
                    style={{ left: '30px' }} // Ensure inline style matches your CSS changes
                    onClick={() => setDrawerOpen(!drawerOpen)}
                >
                    ☰
                </button>
                <div
                    ref={drawerRef}
                    className={`drawer ${drawerOpen ? 'open' : ''}`}
                    style={{ left: '30px' }} // Ensure inline style matches your CSS changes
                >
                    <div className="drawer-content">
                        <button className="back-button-free" onClick={() => navigate('/select-doll')}> {'<- Return to Doll Selection'}</button>
                        <button className="outfit-toggle" onClick={toggleOutfitSet}>
                            {currentOutfitSet === 'classic' ? 'Switch to Bad Batch' : 'Switch to Classic'}
                        </button>
                        <button className="hair-button" onClick={() => setHairDo(!hairDo)}> {hairDo ? 'Hair On' : 'Hair Off'}</button>

                        {/* Lightsaber Toggle Buttons */}
                        <div className="lightsaber-control">
                            <h4
                                onClick={() => setLightsaberControlsOpen(!lightsaberControlsOpen)}
                                style={{ cursor: 'pointer', margin: '10px 0' }}
                            >
                                Lightsaber Controls {lightsaberControlsOpen ? '▼' : '▶'}
                            </h4>
                            {lightsaberControlsOpen && (
                                <div className="saber-buttons">
                                    {/* Group Toggles */}
                                    <button onClick={() => toggleAllSabers('off')}>Turn All Off</button>
                                    <button onClick={() => toggleAllSabers('red')}>Switch to Red</button>
                                    <button onClick={() => toggleAllSabers('yellow')}>Switch to Yellow</button>
                                    {/* Individual Toggles */}
                                    <button onClick={() => toggleSaberVisibility('frontRed')} className={saberState.frontRed ? 'active' : ''}>
                                        Front Red
                                    </button>
                                    <button onClick={() => toggleSaberVisibility('backRed')} className={saberState.backRed ? 'active' : ''}>
                                        Back Red
                                    </button>
                                    <button onClick={() => toggleSaberVisibility('frontYellow')} className={saberState.frontYellow ? 'active' : ''}>
                                        Front Yellow
                                    </button>
                                </div>
                            )}
                        </div>




                        {/* Categorized Outfits */}
                        {Object.entries(
                            Object.keys(fixedOutfitPositions).reduce((categories, outfitId) => {
                                // Dynamically categorize based on outfit ID patterns or predefined rules
                                const category = outfitId.includes('top') ? 'Tops' :
                                    outfitId.includes('bottom') ? 'Bottoms' :
                                        outfitId.includes('acc') ? 'Accessories' :
                                            'Other';

                                if (!categories[category]) categories[category] = [];
                                categories[category].push(outfitId);
                                return categories;
                            }, {})
                        ).map(([category, outfitIds]) => (
                            <div key={category} className="outfit-category">
                                {/* Category Header */}
                                <h3
                                    className="category-header"
                                    onClick={() => setCategoriesOpen((prev) => ({
                                        ...prev,
                                        [category]: !prev[category],
                                    }))}
                                >
                                    {category}
                                    {categoriesOpen[category] ? ' ▼' : ' ▶'}
                                </h3>

                                {/* Outfits in the Category */}
                                {categoriesOpen[category] && (
                                    <div className="category-items">
                                        {outfitIds.map((id) => {
                                            const isAdded = outfits.find((o) => o.id === id);
                                            const { src } = fixedOutfitPositions[id];
                                            return (
                                                <div key={id} className="menu-item">
                                                    <span>{id.replace(/-/g, ' ')}</span>
                                                    <div className="button-container">
                                                        {!isAdded ? (
                                                            <button onClick={() => handleAddOutfit({ id, src })}>Add</button>
                                                        ) : (
                                                            <>
                                                                <button onClick={() => handleRemoveOutfit(id)}>Remove</button>
                                                                <button onClick={() => handleBringToFront(id)}>Front</button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>


                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>



                <Stage width={containerSize.width} height={containerSize.height}>
                    {/* Background Layer */}
                    <Layer>
                        {AsajjMain && (
                            <KonvaImage
                                image={AsajjMain}
                                width={1024 * scale}
                                height={1920 * scale}
                                x={0}
                                y={0}
                            />
                        )}
                    </Layer>

                    {/* Back Saber Blade and Hilt (Classic Red Only) */}
                    <Layer>
                        {saberState && saberState.backRed && backSaberBladeAndHilt && (
                            <KonvaImage
                                image={backSaberBladeAndHilt}
                                width={1024 * scale}
                                height={1920 * scale}
                                x={0}
                                y={0}
                            />
                        )}
                    </Layer>

                    {/* Asajj-body Layer */}
                    <Layer>
                        {asajjBody && (
                            <KonvaImage
                                image={asajjBody}
                                width={1024 * scale}
                                height={1920 * scale}
                                x={0}
                                y={0}
                            />
                        )}
                    </Layer>

                    {/* Back Saber Hilt */}
                    <Layer>
                        {saberState && saberState.backRed && backSaberHilt && (
                            <KonvaImage
                                image={backSaberHilt}
                                width={1024 * scale}
                                height={1920 * scale}
                                x={0}
                                y={0}
                            />
                        )}
                    </Layer>

                    {/* Outfits Layer */}
                    <Layer>
                        {outfits.map((outfit) => (
                            outfit.src && (
                                <DraggableImage
                                    key={outfit.id}
                                    id={outfit.id}
                                    src={outfit.src}
                                    x={outfit.x}
                                    y={outfit.y}
                                    naturalWidth={outfit.naturalWidth}
                                    naturalHeight={outfit.naturalHeight}
                                    scale={scale}
                                    onDragEnd={handleDragEnd}
                                />
                            )
                        ))}
                    </Layer>

                    {/* HairUp Layer (if hairDo is false) */}
                    <Layer>
                        {!hairDo && hair && (
                            <KonvaImage
                                image={hair}
                                width={1024 * scale}
                                height={1920 * scale}
                                x={0}
                                y={0}
                                listening={false}
                            />
                        )}
                    </Layer>

                    {/* Front Red Saber*/}
                    <Layer>
                        {saberState && saberState.frontRed && frontSaber && (
                            <KonvaImage
                                image={frontSaber}
                                width={1024 * scale}
                                height={1920 * scale}
                                x={0}
                                y={0}
                                listening={false}
                            />
                        )}
                    </Layer>

                    {/* Front Yellow Saber*/}
                    <Layer>
                        {/* Front Yellow Saber */}
                        {saberState && saberState.frontYellow && frontSaberYellow && (
                            <KonvaImage
                                image={frontSaberYellow}
                                width={1024 * scale}
                                height={1920 * scale}
                                x={0}
                                y={0}
                                listening={false}
                            />
                        )}
                    </Layer>
                </Stage>

            </div>
            <div className="doll-page-watermark-container" style={{ position: 'absolute', left: '50%', bottom: '100px', zIndex: 2 }}>
                <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
                    <img src={watermarkImage} alt="Watermark" className="doll-page-watermark" />
                </a>
            </div>
        </div>
    );
};

export default AsajjDoll;
