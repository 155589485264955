import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';
import { useNavigate } from 'react-router-dom';
import watermarkImage from '../../assets/images/AI-Watermark.png';
import '../../App.css';

// Draggable Image Component
const DraggableImage = ({ id, src, x, y, scale, onDragEnd }) => {
  const [image] = useImage(src);
  const [pos, setPos] = useState({ x, y });

  const handleDragMove = (e) => {
    let newX = e.target.x();
    let newY = e.target.y();
    setPos({ x: newX, y: newY });
  };

  const handleDragEndFunc = () => {
    if (onDragEnd) {
      onDragEnd(id, pos.x, pos.y);
    }
  };

  return (
    <KonvaImage
      image={image}
      x={pos.x}
      y={pos.y}
      width={1024 * scale} // Uniform scaling applied to the width
      height={1920 * scale} // Uniform scaling applied to the height
      draggable
      onDragMove={handleDragMove}
      onDragEnd={handleDragEndFunc}
    />
  );
};

// Main Doll Component
const SallyDoll = () => {
  const [outfits, setOutfits] = useState([]);
  const [initialOutfits, setInitialOutfits] = useState({});
  const [SallyDollMain] = useImage(require('../../assets/images/dolls/Sally/Sally_Main.jpg'));
  const [topHands] = useImage(require('../../assets/images/dolls/Sally/top-hands.png'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const drawerRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [imageTopOffset, setImageTopOffset] = useState(0);
  const [containerSize, setContainerSize] = useState({ width: 1024, height: 1920 });
  const navigate = useNavigate();

  // Function to update scale and container dimensions
  useEffect(() => {
    const updateScaleAndContainer = () => {
      const windowHeight = window.innerHeight;
      const originalHeight = 1920; // Height of the doll image

      // Use the height of the window to set the scale for the entire canvas
      const newScale = windowHeight / originalHeight;

      const containerWidth = 1024 * newScale;
      const containerHeight = originalHeight * newScale;

      setScale(newScale);
      setImageTopOffset(20 * newScale);
      setContainerSize({ width: containerWidth, height: containerHeight });
    };

    updateScaleAndContainer();
    window.addEventListener('resize', updateScaleAndContainer);

    return () => window.removeEventListener('resize', updateScaleAndContainer);
  }, []);

  // Adjust outfit positions based on container size and scale
  const adjustOutfitPosition = () => {
    const outfitCenterX = (containerSize.width - 1024 * scale) / 2;
    const outfitCenterY = imageTopOffset;

    const initialOutfitsData = [
      { id: 'white-panty-bottom', src: require('../../assets/images/dolls/Sally/outfits/white_panty_bottom.png'), x: outfitCenterX, y: outfitCenterY },
      { id: 'white_tube_top', src: require('../../assets/images/dolls/Sally/outfits/white_tube_top.png'), x: outfitCenterX, y: outfitCenterY },
      { id: 'sally_heels', src: require('../../assets/images/dolls/Sally/outfits/sally_heels.png'), x: outfitCenterX, y: outfitCenterY },
      { id: 'sally_dress', src: require('../../assets/images/dolls/Sally/outfits/sally_dress.png'), x: outfitCenterX, y: outfitCenterY },
    ];

    setOutfits(initialOutfitsData);

    const initialPositions = {};
    initialOutfitsData.forEach(outfit => {
      initialPositions[outfit.id] = { x: outfitCenterX, y: outfitCenterY };
    });
    setInitialOutfits(initialPositions);
  };

  // Adjust outfit position on load and container size change
  useEffect(() => {
    adjustOutfitPosition();
  }, [scale, imageTopOffset, containerSize]);

  const handleAddOutfit = (outfit) => {
    const outfitCenterX = (containerSize.width - 1024 * scale) / 2;
    const outfitCenterY = imageTopOffset;

    if (!outfits.find(o => o.id === outfit.id)) {
      setOutfits([...outfits, { ...outfit, x: outfitCenterX, y: outfitCenterY }]);
      setInitialOutfits(prev => ({
        ...prev,
        [outfit.id]: { x: outfitCenterX, y: outfitCenterY }
      }));
    }
  };

  const handleRemoveOutfit = (id) => {
    setOutfits(outfits.filter(outfit => outfit.id !== id));
  };

  const handleBringToFront = (id) => {
    const outfit = outfits.find(outfit => outfit.id === id);
    setOutfits(outfits.filter(outfit => outfit.id !== id).concat(outfit));
  };

  const handleDragEnd = (id, x, y) => {
    const initialPosition = initialOutfits[id];
    const dragThreshold = 50;

    const hasMovedFar = Math.abs(x - initialPosition.x) > dragThreshold || Math.abs(y - initialPosition.y) > dragThreshold;

    if (hasMovedFar) {
      handleRemoveOutfit(id);
    } else {
      setOutfits(outfits.map(outfit => (outfit.id === id ? { ...outfit, x, y } : outfit)));
    }
  };


  return (
    <div className="doll-page" style={{ display: 'flex', justifyContent: 'center' }}>
      {/* Game container that holds the doll and outfits */}
      <div style={{ width: containerSize.width, height: containerSize.height, position: 'relative' }}>
        <button className="drawer-toggle" onClick={() => setDrawerOpen(!drawerOpen)}>☰</button>
        <div ref={drawerRef} className={`drawer ${drawerOpen ? 'open' : ''}`}>
          <div className="drawer-content">
            <button className="back-button-free" onClick={() => navigate('/select-doll')}> {'<- Return to Doll Selection'}</button>
            {/* Drawer outfit options */}
            {[
              { id: 'white-panty-bottom', name: 'White Panties', src: require('../../assets/images/dolls/Sally/outfits/white_panty_bottom.png'), },
              { id: 'white_tube_top', name: 'White Tube Top', src: require('../../assets/images/dolls/Sally/outfits/white_tube_top.png'), },
              { id: 'sally_heels', name: 'Sally Heels', src: require('../../assets/images/dolls/Sally/outfits/sally_heels.png') },
              { id: 'sally_dress', names: 'Sally Dress', src: require('../../assets/images/dolls/Sally/outfits/sally_dress.png') },
              { id: 'black_lingerie', name: 'Black Lingerie', src: require('../../assets/images/dolls/Sally/outfits/black-lingerie.png') },
              { id: 'black_goth_heels', name: 'Black Goth Heels', src: require('../../assets/images/dolls/Sally/outfits/black_goth_heels.png') },
              { id: 'black-goth-dress', name: 'Black Goth Dress', src: require('../../assets/images/dolls/Sally/outfits/black_goth_dress.png') },
            ].map(outfit => (
              <div key={outfit.id} className="menu-item">
                <span>{outfit.name}</span>
                {!outfits.find(o => o.id === outfit.id) && (
                  <button onClick={() => handleAddOutfit(outfit)}>Add</button>
                )}
                {outfits.find(o => o.id === outfit.id) && (
                  <>
                    <button onClick={() => handleRemoveOutfit(outfit.id)}>Remove</button>
                    <button onClick={() => handleBringToFront(outfit.id)}>Bring to Front</button>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>

        <Stage width={containerSize.width} height={containerSize.height}>
          <Layer>
            <KonvaImage
              image={SallyDollMain}
              width={1024 * scale}
              height={1920 * scale}
              x={0}
              y={0}
            />
            {outfits.map(outfit => (
              <DraggableImage
                key={outfit.id}
                id={outfit.id}
                src={outfit.src}
                x={outfit.x}
                y={outfit.y}
                scale={scale} // Pass the uniform scale to the outfits
                onDragEnd={handleDragEnd}
              />
            ))}
          </Layer>

          <Layer>
            <KonvaImage
              image={topHands}
              width={1024 * scale}
              height={1920 * scale}
              x={0}
              y={0}
              listening={false}
            />
          </Layer>
        </Stage>
      </div>

      <div
        className="doll-page-watermark-container"
        style={{ position: 'absolute', left: '50%', bottom: '100px', zIndex: 2 }}
      >
        <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
          <img src={watermarkImage} alt="Watermark" className="doll-page-watermark" />
        </a>
      </div>
    </div>
  );
};

export default SallyDoll;
